function navigationNotice(html) {
    $("#account-notice")
        .removeClass('d-none')
        .html(`
<p>
<svg xmlns="http://www.w3.org/2000/svg" class="bi" style="height: 24px; width: 24px; color:orange;" viewBox="0 0 16 16">
    <path fill="currentColor" d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
</svg>
${html}
</p>`);
}

function profileNotice(html) {
    $("#profile-notice")
        .removeClass('d-none')
        .html(`
<svg xmlns="http://www.w3.org/2000/svg" class="bi" style="height: 24px; width: 24px; color:orange;" viewBox="0 0 16 16">
    <path fill="currentColor" d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
</svg>
${html}`);
}

function updateNavigationBarEarly() {
    profile = getUserProfileInfo();
    if (! profile || 
        window.location.pathname === "/signup-consent.html" ||
        window.location.pathname === "/signup-plan.html") {
        $("#loginbutton").removeClass("d-none");
        $("#consolebutton").addClass("d-none");
    } else {
        $("#loginbutton").addClass("d-none");
        $("#consolebutton").removeClass("d-none");
    }
}

// function updateNavigationBar() {
//     profile = getUserProfileInfo();

//     // The functionality of the profile menu is disabled
//     // in the signup pages, so that users don't get diverted
//     // .. and they likely wouldn't work correctly anyway
//     /*
//     if (window.location.pathname === "/signup-consent.html" || 
//         window.location.pathname === "/signup-plan.html") {
//         $("#profile-manage-account-button").hide();
//         $("#profile-manage-subscription-button").hide();
//     }
//     */

//     if (profile.status == "SUSPENDED_UNPAID" || profile.status == "SUSPENDED_ADMIN") {
//         $("#profile-manage-account").addClass("d-none");
//         $("#profile-manage-subscription").addClass("d-none");

//         // TODO: Add suspension reason
//         profileNotice(`
//             <b>Your account is suspended.</b>
//             <p>Please contact <a href="mailto:support@photovaultone.com">support@photovaultone.com</a></p>
//         `);

//         navigationNotice(`
//             Your account is suspended. Please contact <a href="mailto:support@photovaultone.com">support@photovaultone.com</a>
//         `);

//     } else if (profile.status == "NEW_PENDING") {
//         $("#profile-manage-account").addClass("d-none");
//         $("#profile-manage-subscription").addClass("d-none");

//         resume_url = "/signup-plan.html";

//         if (! (window.location.pathname.startsWith('/signup-') || 
//             window.location.pathname.startsWith('/payment-'))) {
//                 profileNotice(`
//                     <b>Your signup is incomplete</b>
//                     <p><a href="${resume_url}">Resume your signup</a></p>
//                 `);

//                 navigationNotice(`
//                     Your subscription checkout is pending. Please <a href="${resume_url}">resume your signup</a>.
//                 `);
//         }

//     } else if (profile.status == "NEW") {
//         // if the profile setup is incomplete (status == NEW),
//         // and the user is not on the signup pages, show the 
//         // account notice element
//         $("#profile-manage-account").addClass("d-none");
//         $("#profile-manage-subscription").addClass("d-none");

//         if (profile.have_credentials) {
//             resume_url = "/signup-plan.html";
//         } else {
//             resume_url = "/signup-consent.html";
//         }

//         if (! (window.location.pathname.startsWith('/signup-') || 
//             window.location.pathname.startsWith('/payment-'))) {
//                 profileNotice(`
//                     <b>Your signup is incomplete</b>
//                     <p><a href="${resume_url}">Resume your signup</a></p>
//                 `);

//                 navigationNotice(`
//                     Your account signup is incomplete. Please <a href="${resume_url}">resume your signup</a>.
//                 `);
//         }
//     } else {
//         // reset visibility of elements back to defaults
//         $("#account-notice").addClass("d-none");
//         $("#profile-notice").addClass("d-none");
//         $("#profile-manage-account").removeClass("d-none")
//         $("#profile-manage-subscription").removeClass("d-none")
//     }   


//     $("#nav-profile-img").click(function () {
//         // Calculate where the modal should be positioned
//         button = $("#nav-profile-img");
//         button_offset = button.offset()
//         console.log("left:"+button_offset.left)
//         console.log("top:"+button_offset.top)

//         $('#profilemodal').modal('show');
//         $('#profilemodaldialog').offset({left: button_offset.left - $('#profilemodaldialog').width() + 50, top: button_offset.top + 30 }) 
//     });

//     $("#profile-manage-account").click(function () {
//         $("#profilemodal").modal('hide');
//         loadingOverlay();
//         api_url = getSessionItem('api_url');
//         $.get({
//             url: api_url + '/profile',
//             headers: {'x-photovaultone': getSessionItem('csrf_token')},
//             crossDomain: true,
//             xhrFields: { withCredentials: true },
//             success: function(res) {
//                 loadingOverlay(cancel=true);
//                 setSessionItem("userinfo", res.userinfo);
//                 window.location.href = "/console/";
//             },
//         });
//     });

//     $("#profile-manage-subscription").click(function () {
//         $("#profilemodal").modal('hide');
//         loadingOverlay();
//         api_url = getSessionItem('api_url');
//         $.get({
//             url: api_url + '/subscription',
//             headers: {'x-photovaultone': getSessionItem('csrf_token')},
//             crossDomain: true,
//             xhrFields: { withCredentials: true },
//             success: function(res) {
//                 loadingOverlay(cancel=true);
//                 window.location.href = res.url;
//             },
//         });
//     });

//     $("#profile-signout").click(function() {
//         // Erasing the api session cookie requires an api call, which
//         // sets an expired cookie
//         $("#profilemodal").modal('hide');
//         loadingOverlay();
//         api_url = getSessionItem('api_url');
//         $.post({
//             url: api_url + '/logout',
//             headers: {'x-photovaultone': getSessionItem('csrf_token')},
//             crossDomain: true,
//             xhrFields: { withCredentials: true },
//             success: function(res) {
//                 loadingOverlay(cancel=true);
//                 removeSessionItem("userinfo");
//                 removeSessionItem("api_url");
//                 removeSessionItem("csrf_token");
//                 window.location.href = "/";
//             },        
//         });

//     });
// };

$(document).ready(function () {
    cookie_accept = window.localStorage.getItem('accept_cookies');
    if (! cookie_accept) {
        $("#cookie-banner").removeClass("d-none");
    }

    $("#cookie-banner button").click(function() {
        window.localStorage.setItem('accept_cookies', true)
        $("#cookie-banner").addClass("d-none");
    });

    $("#consolebutton").click(function () {
        // First refresh the users profile info before opening console
        api_url = getSessionItem('api_url');
        loadingOverlay();
        $.get({
            url: api_url + '/profile',
            headers: {'x-photovaultone': getSessionItem('csrf_token')},
            crossDomain: true,
            xhrFields: { withCredentials: true },
            // Do not run global error handler; being redirected to error.html
            // here is not desired.
            global: false,
            success: function(res) {
                setSessionItem("userinfo", res.userinfo);
                loadingOverlay(cancel=true);
                window.location.href = "/console/";
            },
            error: function(res, status, error) {
                console.log("failed to fetch profile");
                invalidateSession();
                window.location.href = "/login.html";            },
        });
        return false;
    });        

    // profile = getUserProfileInfo();
    // if (! profile) {
    //     return
    // }

    // // Logged in users with a status other than ENABLED
    // // will trigger a check for a periodic refresh of the profile
    // // at each page load. This is to make sure that
    // // the user is not displayed out of date information
    // // about the signup progress, payment, or otherwise.
    // updateNavigationBar();
    // if (profile.status != 'ENABLED') {
    //     now = Math.floor(Date.now() / 1000);
    //     userinfo_loaded = getSessionItem('userinfo_timestamp');
    //     if (!userinfo_loaded || (userinfo_loaded && (now - userinfo_loaded) >= 60)) {
    //         api_url = getSessionItem('api_url');
    //         $.get({
    //             url: api_url + '/profile',
    //             headers: {'x-photovaultone': getSessionItem('csrf_token')},
    //             crossDomain: true,
    //             xhrFields: { withCredentials: true },
    //             // Do not run global error handler; being redirected to error.html
    //             // here is not desired.
    //             global: false,
    //             success: function(res) {
    //                 setSessionItem("userinfo", res.userinfo);
    //                 setSessionItem("userinfo_loaded", Math.floor(Date.now() / 1000));
    //                 updateNavigationBar();
    //             },
    //             error: function(res, status, error) {
    //                 // errors are ignore this time
    //                 console.log("failed to fetch profile");
    //             },
    //         });
    //     }
    // }


});

if (isSessionExpired()) {
    invalidateSession();
    window.location.href = "/";
}

updateNavigationBarEarly();